
.Wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25) ), url('../login/pexels-anna-shvets-5324857.jpg') center no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.WrapperLoggedIn {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: linear-gradient( rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.6)), url('./mayne.png') center no-repeat;
  background-size: cover;
  color: white;
}

.Inner {
  margin: 0 auto;
  width: 1100px;
  max-width: 100%;
  z-index: 1;
}

.Logo {
  position: absolute;
  top: 2em;
  left: 2em;
  width: 60px;
}

.BottomDivider {
    position: absolute;
    bottom: 0;
    left: 0;
    flex: 1%;
    pointer-events: none;
    height: 96px;
}

.BottomDivider svg {
    flex: 1%;
    height: 100%;
    position: relative;
}

.EmployeeAccessButton {
    width: 130px;
    display: inline-flex;
    padding: .85em 1.5em;
    font-size: 15px;
    line-height: 1em;
    text-decoration: none;
    border: 2px solid white;
    border-radius: 50em;
    color: white;
    transition: all .3s;
}

.EmployeeAccessButton:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
    transform: translate3d(0, -2px, 0);
    box-shadow: 0 12px 28px rgb(0 0 0 / 12%);
}

.BottomDivider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  height: 96px;
}

.BottomDivider svg {
    width: 100%;
    height: 100%;
    position: relative;
}

.EmployeeAccessButton {
  position: absolute;
  top: 2em;
  right: 3em;
  display: inline-flex;
  padding: .85em 1.5em;
  font-size: 15px;
  line-height: 1em;
  text-decoration: none;
  border: 2px solid white;
  border-radius: 50em;
  color: white;
  transition: all .3s;
}

.EmployeeAccessButton:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
    transform: translate3d(0,-2px,0);
    box-shadow: 0 12px 28px rgb(0 0 0 / 12%);
}
