
.Wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('./easter.png') center no-repeat;
    background-size: cover;
}

.WrapperTV {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
    ), url('./tv.jpg') center no-repeat;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.25);
    padding: 20px;
}
